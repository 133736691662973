.video-player {
  //width: 100%;
  max-width: 1080px;
  min-width: 300px;
  margin: 0 auto;
  background-color: #000;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;

  video {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
  }

  .controls {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0 0 8px 8px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 10px;

    right: 0;
    left: 0;
    z-index: 20;

    &:not(.playing){
      .stop-button {
        display: none;
      }
    }
  }

  button {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    outline: none;
  }

  .progress-bar {
    width: 100%;
    height: 16px;
    background-color: #424242;
    border-radius: 8px;
    margin-bottom: 5px;

    .progress {
      height: 100%;
      background-color: #ffffff;
      border-radius: 8px;
    }
  }

  .center-play-button {
    position: absolute;
    z-index: 15;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0);
    border: none;
    border-radius: 50%;
    color: #fff;
    font-size: 48px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover .center-play-button,
  &:not(.playing) .center-play-button {
    opacity: 1;
  }

  .playing {
    .cover {
      opacity: 0;
    }
  }

  .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 15;

    .error-message {

    }
  }

  .cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
