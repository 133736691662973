@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1f1f20 !important
}

* {
  font-family: 'Outfit', sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.shuri-search-wrap {
  margin: 0;
  padding: 0;
}
.shuri-search-wrap .shuri-search{
  border: none;
  background: none
}

.shuri-search-wrap .shuri-search:hover {
  background: none;
}