.amplify-tabs-item[aria-selected="true"] {
    color: #5BC0E9;
}

.amplify-tabs-item[aria-selected="false"] {
    color: black;
}

.amplify-button[data-variation='primary'] {
    background: purple;
}

body {
    background-color: #272729;
    background-image: url('shapes.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: left;
}
img {
    opacity: 0.5;
  }

div[dir="ltr"] {
    min-height: 480px;
}