.gm-style .gm-style-iw-c {
    padding-right: 10px;
    padding-bottom: 0px;
    max-width: 30rem;
    max-height: 30rem;
    min-width: 20rem;
    box-sizing: border-box;
    overflow: hidden;
    background-color: #fff;
}
.gm-style .gm-style-iw-d {
    overflow: hidden;
}
